import { Container, Grid, Typography, Box, Divider } from '@mui/material';
import './App.css';
import CoinPayments from './components/experience/CoinPayments';
import Remax from './components/experience/Remax';
import RecordsByMail from './components/experience/RecordsByMail';
import Compli from './components/experience/Compli';
import Portfolio from './components/Portfolio';
import Profile from './components/Profile';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Masonry from '@mui/lab/Masonry';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <div>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container mx={4}>
        <Box mt={5}>
          <Typography mb={2} component="div" variant="h5" fontFamily="Share Tech Mono">Profile</Typography>

          <Profile />
        </Box>

        <Box my={5}>
          <Typography mb={2} component="div" variant="h5" fontFamily="Share Tech Mono">Experience</Typography>
          <Masonry columns={{ sm: 1, md: 2 }} sx={{ width: "auto" }}>
              <CoinPayments />
              <Remax />
              <RecordsByMail />
              <Compli />
          </Masonry>
        </Box>
        
        <Box>
          <Typography mb={2} component="div" variant="h5" fontFamily="Share Tech Mono">Portfolio</Typography>

          <Portfolio />
        </Box>

        </Container>
        </ThemeProvider>

    </div>
  );
}

export default App;
