import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, CardHeader, Grid, Card, CardMedia, CardContent, Chip, List, ListItem, ListItemText, Divider } from '@mui/material';
import NameValue from '../NameValue';
import remax from '../../assets/images/remax.jpeg';

export default function Remax() {
    return (
        <>
            <Card sx={{ boxShadow: 'none' }}>
            
                <CardHeader title="Remax" subheader="Full Stack Developer" 
                avatar={
          <Avatar src={remax} />} />
            
                <CardContent sx={{ paddingTop: 0 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box>
                                <Chip label="Vue" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="Laravel" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="Tailwind" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="MySQL" sx={{ marginRight: 1, marginBottom: 1 }} />
                                {/* <Chip label="Elastic Search" sx={{ marginRight: 1 }} /> */}
                                <Chip label="Docker" sx={{ marginBottom: 1 }} />
                            </Box>               
                        </Grid>

                        <Grid item xs={12}>
                            <List sx={{ marginY: 3 }}>
                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Top performer known for prioritizing unit tests." />
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Inspired team to push out clean code and tackle the most challenging tasks." />
                                </ListItem>
                                <Divider />

                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Quickly promoted after showing impressive skillsets, ambition, and tenacity." />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <NameValue name="duration" value="1 year 6 months" />
                        </Grid>

                        <Grid item xs={6}>
                            <NameValue name="industry" value="Real Estate" />
                        </Grid>
                        
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
