import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationIcon from '@mui/icons-material/LocationCity';
import GitIcon from '@mui/icons-material/GitHub';
import profileImg from '../assets/images/tim-profile-picture.jpeg';
import { Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useMediaQuery } from '@mui/material';

export default function Profile() {
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:800px)');

  return (
    <Card sx={{ display: 'flex', width: '100%', boxShadow: 'none' }}>
    {matches && <CardMedia
        component="img"
        sx={{ width: 200 }}
        image={profileImg}
      />}
      {/* <Box sx={{ display: 'flex', flexDirection: 'column' }}> */}
        <CardContent >
        <Grid container spacing={6}>

            <Grid item xs={12} sm={6} >
                <Box ml={2} >
                    <Typography component="div" variant="h5">
                        Tim Zagelow
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        Full Stack Developer
                    </Typography>

                    <Typography fontSize={14} fontStyle="italic" color="#6f6f6f" display="inline">
                        Passionate and ambitious about solving technology problems!
                    </Typography>
                </Box>

                <Box mt={3}>
                    <List disablePadding>
                        <ListItem dense={true}>
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                <Link href="mailto: timzagelow@gmail.com">timzagelow@gmail.com</Link>
                        </ListItem>

                        <ListItem dense={true}>
                                <ListItemIcon>
                                    <PhoneIcon />
                                </ListItemIcon>
                                <Link href="tel:+15037588384">503-758-8384</Link>
                        </ListItem>

                        <ListItem dense={true}>
                                <ListItemIcon>
                                    <LocationIcon />
                                </ListItemIcon>
                                <ListItemText primary="Bozeman, MT" />
                        </ListItem>

                        <ListItem dense={true}>
                                <ListItemIcon>
                                    <GitIcon />
                                </ListItemIcon>
                                <Link href="https://github.com/timzagelow" target="_blank">GitHub</Link>
                        </ListItem>
                    </List>
                </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Box ml={2}>
                    <Typography component="div" variant="h6">
                        Interests
                    </Typography>
                </Box>
                    <List disablePadding>
                        <ListItem dense={true}>
                            <ListItemIcon>💪</ListItemIcon>
                            <ListItemText primary="Weight Lifting" secondary="Strong body, strong mind" />
                        </ListItem>
                        <ListItem dense={true}>
                            <ListItemIcon>🔗</ListItemIcon>
                            <ListItemText primary="Blockchain / Web3" secondary="Smart contract development in Rust" />
                        </ListItem>
                        <ListItem dense={true}>
                            <ListItemIcon>💿</ListItemIcon>
                            <ListItemText primary="Vinyl Records" secondary="Scratch DJ for 15 years" />
                        </ListItem>
                        <ListItem dense={true}>
                            <ListItemIcon>🌮</ListItemIcon>
                            <ListItemText primary="Tacos" secondary="Lived in Mexico for 2 years" />
                        </ListItem>
                    </List>
 

            </Grid>

          </Grid>


          
        </CardContent>
        
      {/* </Box> */}
      
    </Card>
  );
}