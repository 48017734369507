import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useMediaQuery } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import rbmSite from "../assets/images/rbm-site.png";
import toolidarity from "../assets/images/toolidarity.png";
import enlightenment from "../assets/images/enlightenment.png";
import laboog from "../assets/images/laboog.png";
import toonies from "../assets/images/toonies.png";
import twitter from "../assets/images/twitter.png";
import { Button, Chip, Link } from '@mui/material';

export default function Portfolio() {
  const matches = useMediaQuery('(min-width:800px)');

    return (
      <ImageList cols={matches ? 2 : 1} variant="masonry" gap={8}>
        {/* <ImageListItem key="Subheader" cols={2}>
          <ListSubheader component="div">Portfolio</ListSubheader>
        </ImageListItem> */}
        {itemData.map((item) => (
          <ImageListItem key={item.img} >
            <img
              srcSet={`${item.img}?fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              // sx={{ bgcolor: 'rgba(0, 0, 0, 0.6)' }}
              title={<><Button variant="link" size="small" href={item.link} target="_blank" endIcon={<OpenInNew />} >
                {item.title}
              </Button></>}
              subtitle={<Typography pl={1.5} fontSize={12}>{item.subtitle}</Typography>}
              // actionIcon={
              //     <Chip sx={{ marginRight: 2 }} label={item.type} />
              // }
              position={matches ? 'bottom' : 'below'}
              sx={{ marginBottom: matches ? 0 : 5 }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    );
  }
  
  const itemData = [
    {
      img: rbmSite,
      title: 'Records By Mail',
      subtitle:  <>
        <Chip label="Vue" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Bootstrap" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Node" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Mongo" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="AWS" size="small" sx={{ bgcolor: '#7f7f7f' }} />
        </>,
      type: 'E-Commerce',
      link: 'https://recordsbymail.com',
    },
   
    {
      img: enlightenment,
      title: 'Enlightenment',
      subtitle:  <>
        <Chip label="React" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Typescript" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Material UI" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Rust" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Node" size="small" sx={{ bgcolor: '#7f7f7f' }} />
        </>,
      type: 'NFT Art Upgrade',
      link: 'https://drinkmelk.cets.wtf',
    },
    {
      img: toonies,
      title: 'Toonies Burn',
      subtitle:  <>
        <Chip label="React" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Material UI" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Typescript" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Rust" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Firebase" size="small" sx={{ bgcolor: '#7f7f7f' }} />
        </>,
      type: 'Phygital',
      link: 'https://tooniesburn.com',
    },
    {
      img: toolidarity,
      title: 'Toolidarity',
      subtitle:  <>
      <Chip label="React" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
      <Chip label="Material UI" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
      <Chip label="Node" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
      <Chip label="MySQL" size="small" sx={{ bgcolor: '#7f7f7f' }} />
      </>,
      type: 'Tool Aggregator',
      link: 'https://toolidarity.app',
    },
    {
      img: laboog,
      title: 'Laboog',
      subtitle:  <>
        <Chip label="React" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Material UI" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Rust" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Node" size="small" sx={{ bgcolor: '#7f7f7f' }} />
        </>,
      type: 'Meme Contest',
      link: 'https://wtfislaboog.com',
    },
    {
      img: twitter,
      title: 'Cets Sales',
      subtitle:  <>
        <Chip label="Node" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Twitter API" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="Discord API" sx={{ marginRight: 1, bgcolor: '#7f7f7f' }} size="small" />
        <Chip label="web3.js" size="small" sx={{ bgcolor: '#7f7f7f' }} />
        </>,
      type: 'Twitter Bot',
      link: 'https://twitter.com/cets_sales',
    },
  ];