import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, CardHeader, Grid, Card, CardMedia, CardContent, Chip, List, ListItem, ListItemText, Divider } from '@mui/material';
import NameValue from '../NameValue';
import rbm from '../../assets/images/rbm.jpeg';

export default function RecordsByMail() {
    return (
        <>
            <Card sx={{ boxShadow: 'none' }}>
            
                <CardHeader title="Records By Mail" subheader="Principal Engineer" 
                 avatar={
          <Avatar src={rbm} />} />
        
                <CardContent sx={{ paddingTop: 0 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box>
                                <Chip label="Vue" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="Bootstrap" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="Node" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="Mongo" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="Auth0" sx={{ marginBottom: 1 }} />
                            </Box>               
                        </Grid>

                        <Grid item xs={12}>
                            <List sx={{ marginY: 3 }}>
                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Built entire e-commerce platform and back office app." />
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Deployed numerous microservices for third party integrations." />
                                </ListItem>
                                <Divider />

                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Acted as Chief Operating Officer to drive sales & engineering efforts." />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <NameValue name="duration" value="10 years" />
                        </Grid>

                        <Grid item xs={6}>
                            <NameValue name="industry" value="E-Commerce" />
                        </Grid>
                        
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
