import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, CardHeader, Grid, Card, Chip, CardContent, List, ListItem, ListItemText, Divider } from '@mui/material';
import NameValue from '../NameValue';
import coinpayments from '../../assets/images/coinpayments.jpeg';

export default function CoinPayments() {
    return (
        <>
            <Card sx={{ boxShadow: 'none' }}>
                <CardHeader title="CoinPayments" subheader="Lead Developer"
                 avatar={
          <Avatar src={coinpayments} />} />
              
                <CardContent sx={{ paddingTop: 0 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box>
                                <Chip label="Linux" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="Apache" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="MySQL" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="PHP" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="Docker" sx={{ marginBottom: 1 }} />
                            </Box>               
                        </Grid>

                        <Grid item xs={12}>
                            <List sx={{ marginY: 3 }}>
                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Led small engineering team migrating to c# platform using strangler pattern." />
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Maintained 50+ validator & RPC nodes for accepted cryptocurrencies." />
                                </ListItem>
                                <Divider />

                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Modernized APIs, webhooks and e-commerce integrations." />
                                </ListItem>

                                {/* <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Created analytics API for transaction, user, and volume reports." />
                                </ListItem> */}
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <NameValue name="duration" value="1 year 1 month" />
                        </Grid>

                        <Grid item xs={6}>
                            <NameValue name="industry" value="Cryptocurrency" />
                        </Grid>
                        
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
