import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, CardHeader, Grid, Card, CardMedia, CardContent, Chip, List, ListItem, ListItemText, Divider } from '@mui/material';
import NameValue from '../NameValue';
import compli from '../../assets/images/compli.png';

export default function Compli() {
    return (
        <>
            <Card sx={{ boxShadow: 'none' }}>
            
                <CardHeader title="Compli" subheader="Lead Developer" 
                 avatar={
          <Avatar src={compli} />} />
                <CardContent sx={{ paddingTop: 0 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box>
                                <Chip label="Vue" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="Node" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="Mongo" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="PHP" sx={{ marginRight: 1, marginBottom: 1 }} />
                                <Chip label="Kubernetes" sx={{ marginBottom: 1 }} />
                            </Box>               
                        </Grid>

                        <Grid item xs={12}>
                            <List sx={{ marginY: 3 }}>
                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Spearheaded transition from monolith to microservice architecture." />
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Promoted to team lead after showing leadership, competence and high productivity." />
                                </ListItem>
                                <Divider />

                                <ListItem disablePadding>
                                    <ListItemText primary=
                                    "Interviewed, vetted and mentored numerous developers." />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <NameValue name="duration" value="2 years 6 months" />
                        </Grid>

                        <Grid item xs={6}>
                            <NameValue name="industry" value="Compliance" />
                        </Grid>
                        
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
