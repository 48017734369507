import Typography from '@mui/material/Typography';

export default function NameValue(props) {
    return (
        <>
            <Typography fontSize={14} color="text.secondary">
                    {props.name}
            </Typography>
            <Typography variant="body2" fontSize={16}>
                {props.value}
            </Typography>
            
        </>
    )
}
